footer
{
    background-color: black;

    display: flex;
    flex-flow: column;

    z-index: 2;

    height: 300px;

    justify-content: center;

}

.footer-link
{
    font-size: 20px;
    margin: 20px;

    color: white;
    text-decoration: none;
}


/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    footer
    {

    }
}

/*For desktop*/

@media (min-width: 1000px)
{
    footer
    {
        height: 150px;
        flex-flow: row;

        align-items: center;
    }
}