.soft-icon-banner {
  background-color: var(--soft-gray-background);
  width: 400px;
  border-radius: 10px;
  max-height: 100px;

  display: grid;
  grid-template-columns: 20% 80%;

  padding: 20px 20px 20px 20px;

  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-column-gap: 20px;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;

}
.soft-icon-banner-icon {
  border-right: #a2a2a2 2px solid;
  padding-right: 20px;
  padding-top: 4px;
  max-width: 25%;
}

.soft-icon-banner-text {
  font-size: var(--text-m);
  font-weight: 500;
  text-align: center;
  vertical-align: center;
  color: black;

}
.soft-icon-banner:hover {
  cursor: default;
}

.soft-icon-banner-icon:link {
  color: black;
}

.soft-icon-banner-icon:visited {
  color: black;
}

.soft-icon-banner-icon:hover {
  color: var(--primary-hover);
  cursor: pointer;
}



.soft-icon-banner-text:link {
  color: black;
  text-decoration: none;
}

.soft-icon-banner-text:hover {
  color: var(--primary-hover);
  cursor: pointer;
}

@media (max-width: 999px) {
  .soft-icon-banner {
    width: auto;
    margin: 20px 40px 20px 40px;
  }

}




