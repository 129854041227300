.carousel
{
    position: relative;
    height: 16em;
    width: 24em;
}

.carousel img
{
    height: 16em;
    width: 24em;

    border-radius: 10px;

}

.carousel-button
{
    position: absolute;
    top: 44%;


    background-color: transparent;
    border: 0;

    color: white;

}

.carousel-button:hover
{
    background-color: transparent;
}

.carousel-button-container
{

    position: absolute;
    bottom: 0%;

    height: 16em;
    width: 24em;

    border-radius: 10px;

    opacity: 0;

    background-color: rgba(0,0,0,0.16);

    transition: opacity 0.2s ease-in;

}
.carousel-button-container:hover
{
    opacity: 1;
}




.carousel-button-left
{
    left: 5%;
}


.carousel-button-right
{
    right: 5%;
}

/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    .carousel-button-container
    {
        opacity: 1;
        background-color: transparent;
    }
}

@media (max-width: 400px)
{
    .carousel img
    {
        height: 12em;
        width: 18em;
    }

    .carousel-button-container
    {
        height: 12em;
        width: 18em;
    }

    .carousel
    {
        height: 12em;
        width: 18em;
    }

}

/*For desktop*/

@media (min-width: 1000px)
{

}
