.parallax-container
{
    position: relative;
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: hidden;

    perspective: 8px;
    perspective-origin: 0%;


}

.parallax-background
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: translateZ(-2px) scale(1);

}

.parallax-foreground
{
    margin-top: auto;
    margin-bottom: 50px;

    transform-origin: 0;
    transform: translateZ(4px) scale(0.5);

    position: relative;


}

