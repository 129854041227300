/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/montserrat-v25-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-200.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}


/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'FontAwesome';
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot');
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.woff') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.ttf') format('truetype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.svg?#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 300;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(./fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}




:root {
  --primary: rgb(0, 169, 231);
  --primary-hover: rgb(26, 181, 238);
  --secondary: rgb(0, 180, 89);
  --tertiary: rgb(165, 27, 255);
  --text-color: #000000;

  --background-color: white;
  --highlight-element-color: #3e404e;
  --soft-gray-background: #eaeaea;


  --heading-xl: 32px;
  --heading-m: 28px;
  --heading-s: 24px;

  --text-l: 24px;
  --text-m: 18px;


  --heading-xl-mobile: 36px;
  --heading-m-mobile: 30px;
  --heading-s-mobile: 24px;
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 300;

  color: var(--text-color);
  background-color: var(--background-color);

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container
{
  max-width: 960px;
  padding-top: 30px;
  margin: auto;
  position: relative;
}

.paragraph
{

}



a.button, button
{
  border-radius: 6px;

  text-decoration: none;
  color: white;

  border: 0;

  font-size: var(--text-m);
  font-weight: 400;

  background: var(--primary);

  padding: 10px 20px 10px 20px;
  margin: 0px 10px 0px 10px;

}

a.button.button-l
{
  padding: 6px 20px 6px 20px;
  font-size: var(--text-l);
}


a.button.button-xl
{
  padding: 12px 30px 12px 30px;
  font-size: var(--text-l);
}



a.button:hover, button:hover
{
  background: var(--primary-hover);
}

button:hover
{
  cursor: pointer;
}


.ghost-button
{

  border-radius: 6px;
  text-decoration: none;
  font-size: var(--text-m);
  font-weight: 400;

  background-color: transparent;
  width: auto;
  border: white solid 2px;
  color: white;

  padding: 4px 15px 4px 15px;
}

.ghost-button:hover
{
  background-color: rgba(255, 255, 255, 0.1);
}


.ghost-button-l
{
  padding: 6px 20px 6px 20px;
  font-size: 20px;
}


.ghost-button-xl
{
  padding: 12px 30px 12px 30px;
  font-size: 24px;
}



#page-wrap
{
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#page
{

  width: 100%;
  padding-bottom: 20px;
  flex: 1;
}

.transparent
{
  background: transparent;
}


.text-body
{
  font-weight: 400;


  padding: 0px 15px 30px 15px;
}

.text-body a
{
  color: grey;
}

.text-body h2
{

}

.no-padding
{
  padding: 0;
}

.centered
{
  margin: auto;
  width: 50%;
}

/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
  #page
  {
    top: 100px;
    position: relative;

    padding-bottom: 100px;
  }
}

/*For desktop*/

@media (min-width: 1000px)
{

}

