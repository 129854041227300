.feature
{
    display: grid;
    grid-template-columns: 20% 20% 60%;

    padding: 20px 20px 20px 20px;
    margin: 20px 100px 20px 100px;

    border-bottom: #777777 1px solid;
    border-top: #777777 1px solid;

    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-column-gap: 20px;
}

.feature-icon .material-icons
{
    color: #2fc900;
    font-size: 45px;
}

.feature-title
{
    font-size: 26px;
    font-weight: 400;
}

.feature-description
{
    font-size: 18px;
}

/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    .feature
    {
        display: flex;
        flex-flow: column;
        margin: 20px 30px 20px 30px;
        text-align: center;

    }
    .feature-title
    {

    }

    .feature-icon .material-icons
    {
        font-size: 36px;
        margin: 10px;
    }
}

/*For desktop*/

@media (min-width: 1000px)
{
    .feature
    {
        min-height: 100px;
    }
}
