.dropdown
{
    position: relative;
    margin: 0px 15px 0px 15px;

}

.dropdown-title
{
    color: var(--text-color);
    text-align: center;
}

a.dropdown-title
{
    text-decoration: none;
    color: var(--text-color);
}

a.dropdown-title:hover
{
    cursor: pointer;
}

.dropdown-content
{
    display: flex;
    flex-flow: column;

    background-color: #ffffff;

    box-shadow: 0px 4px 15px 10px rgba(0, 0, 0, 0.1);

    padding: 10px 30px 10px 30px;

    position: absolute;

    top: 100%;

    visibility: hidden;
    opacity: 0;

    transition: opacity 0.2s ease-in;
}

a.dropdown-button
{

    border-radius: 6px;

    text-decoration: none;

    font-size: var(--text-m);
    font-weight: 400;

    color: var(--text-color);

    height: 50px;
    padding: 3px 3px 3px 3px;

    display: inline-block;
    vertical-align: central;
    text-align: center;

    line-height: 50px;

    background: transparent;
}

.dropdown:hover .dropdown-content
{
    visibility: visible;
    opacity: 1;
}

a.dropdown-button:hover
{
    background-color: rgba(245, 245, 245, 0.97);
}

.dropdown-title:hover
{
    cursor: default;
}
