.prices-table
{
    width: 100%;

    background-color: rgba(255, 255, 255, 0.97);

    color: black;

    font-size: 24px;

    border-spacing: 0;

    border-radius: 10px;

    table-layout: fixed;


}

.prices
{


}


.prices-table .material-icons
{
    font-size: 32px;
}

.prices-table td
{
    padding: 8px;


    text-align: center;

    font-size: 20px;

}

.prices-table-toprow
{
    font-weight: 400;

    background: rgb(190,0,255);
    background: linear-gradient(155deg, rgba(190,0,255,1) 0%, rgba(0,58,255,1) 100%);

    background-color: rgba(140,14,255,0.97);

    color: white;
}


.prices-table-toprow td
{
    padding-left: 20px;
    padding-right: 20px;

    font-size: 26px;
}

.prices-table-rowtitle
{
    font-size: 28px;
}


.prices-table-mobile
{
    background-color: white;
    color: black;

    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);

    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;

    border-radius: 10px;

    display: flex;
    flex-flow: column;

    justify-content: center;
    align-items: center;

    font-size: 20px;
    padding: 5px;

    padding-bottom: 25px;

    max-width: 350px;

    text-align: center;
}

.prices-row-mobile
{
    display: flex;
    flex-flow: row;

    align-items: center;
    justify-content: center;
    justify-items: center;
}

.prices-title-mobile
{
    font-size: 30px;
    font-weight: 400;
    color: var(--secondary);

    margin: 10px;

    margin-bottom: 20px;

}

.prices-row-mobile-information
{
    font-weight: 400;
}

@media(max-width: 380px)
{
    .prices-table-mobile
    {
        margin-left: 10px;
        margin-right: 10px;
    }
}
