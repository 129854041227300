.contact-form
{
    display: flex;
    flex-flow: row;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;
    margin-top: 10px;
    margin-bottom: 25px;
}


@media (max-width: 999px) {
}