.solid-text-box
{
    display: grid;
    grid-template-columns: 20% 80%;

    align-items: center;


    background-color: white;
    color: black;

    margin: 1em 2em 1em 2em;
    padding: 1em 0em 1em 0em;

    border-radius: 10px;
}

.solid-text-box-last
{
    background-color: var(--primary);
    color: white;


}

.solid-text-box-last .solid-text-box-text
{
    font-weight: 400;
}

#home-steps-lastbox
{
    background-color: var(--primary);
    color: white;
}

.solid-text-box-number
{
    font-size: 48px;
    justify-self: center;
}

.solid-text-box-text
{
    font-size: 18px;
    padding-right: 10px;
}