.info-text
{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;

    padding: 1em;
    flex-shrink: 1;

    height: auto;

    position: sticky;

}

.info-text-title
{
    font-size: 32px;
    margin-top: 10px;

    text-align: center;

}

.info-text-description
{
    font-size: 24px;
    padding: 0em 2em 0em 2em;




}

.info-text-image
{
    border-radius: 10px;
}

/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    .info-text-description
    {
        text-align: center;
    }

    .info-text-image
    {

        max-width: 550px;
    }

    .info-text-title
    {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/*For desktop*/

@media (min-width: 1000px)
{

}


