#page-photos-banner-headline
{
    z-index: 10;
    position: relative;
    top: 30%;
    text-align: center;

    font-size: 48px;

    color: white;
}

#page-photos-banner-subtitle
{
    z-index: 10;
    font-size: 24px;

    color: white;
}

#page-photos-banner-subtitle-container
{
    position: relative;
    top: 42%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-flow: column;
}

#page-photos-banner-mobile
{
    width: 100%;
    position: relative;
}

#page-photos-banner-mobile-image
{
    width: 100%;

}

#page-photos-banner-mobile-overlay
{
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 60%);
    position: absolute;

    color: white;

    left: 0;
    top: 0;

    display: flex;
    flex-flow: column;

    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%

}
