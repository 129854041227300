.info-card
{
    width: 20em;
    height: 21em;
    background-color: #FBFBFB;

    border-radius: 10px;

    color: black;

    margin: 0px 10px 0px 10px;

    display: grid;
    grid-template-rows: 20% 20% 40% 20%;

    justify-content: center;
    justify-items: center;
    justify-items: center;
    align-content: center;
    align-items: center;


    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);

}

.info-card-button
{

    color: white;
    border: black 0px solid;
    width: auto;

    padding: 10px 20px 10px 20px;

}

.info-card-description
{
    align-self: start;
    text-align: center;
    padding: 0em 1em 0em 1em;
}

.info-card .material-icons
{
    padding-top: 10px;
    font-size: 48px;
}

/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    .info-card-button
    {
        font-size: 19px;
    }
}

/*For desktop*/

@media (min-width: 1000px)
{
    .info-card-button
    {
        font-size: 16px;
    }
}
