.skill-circle
{
    width: 150px;
    height: 150px;

    border-radius: 50%;

    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}


.skill-circle-image
{
    width: 90px;
    align-self: center;

}
