#page-flatrate-span
{
  height: 400px;
  background: rgb(152,0,255);
  background: linear-gradient(155deg, rgba(152,0,255,1) 0%, rgba(0,255,246,1) 100%);

  margin: 0;

  color: white;

  display: flex;

  justify-content: center;
  align-content: center;
  align-items: center;
}

@media (max-width: 999px) {
  #page-flatrate-span
  {
    margin-bottom: 30px;
  }

}
