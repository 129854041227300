.option-switch {

}

.option-switch-switch {
  margin: auto;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
}


.option-switch-switch-option {
  margin-right: 5px;
  margin-left: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  height: 20px;

}

.option-switch-switch-option-activated {
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 5px;
}

.option-switch-switch-offer {
  background-color: #33c6ff;
  border-radius: 8px;
  padding: 3px;
  color: white;

  margin: 0px 5px 0px 5px; /*00A9E7FF*/
}