/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #b0b0b0;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #b9b9b9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
    color: var(--text-color);
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: var(--text-color);
}

#burger-menu-menu
{
    font-size: 24px;
}

#burger-menu-menu-item a
{
    color: var(--text-color);
    text-decoration: none;
}

#burger-menu-menu-submenu {
    color: var(--text-color);
}

