#page-websites-span
{
    height: 400px;
    background: rgb(152,0,255);
    background: linear-gradient(155deg, rgba(152,0,255,1) 0%, rgba(0,255,246,1) 100%);

    margin: 0;

    color: white;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;
}

#page-websites-span-title
{
    padding: 15px;
    font-size: var(--heading-xl);
    text-align: center;

}

#page-websites-webtech-title
{
    text-align: center;

    font-size: var(--heading-xl);
}

#page-websites-skills
{
    display: flex;
    flex-flow: row;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;

    margin: 50px 0 50px 0;
}

@media (max-width: 999px) {
    #page-websites-skills
    {
        flex-flow: column;
    }

    #page-websites-skills .skill-circle {
        margin-bottom: 25px;
    }

}
