#home-showcase {
    display: flex;
    flex-flow: column;

    justify-items: center;
    justify-content: center;

    align-content: center;
    align-items: center;

    background: rgb(0,238,221);
    background: linear-gradient(117deg, rgba(0,238,221,1) 8%, rgba(0,123,238,1) 52%, rgba(71,225,233,1) 90%);

    height: 750px;
}


#home-showcase-title {
    max-width: 450px;
    text-align: center;
    padding: 0px 2em 0px 2em;
    font-size: var(--heading-xl);

    color: white;
}

#home-showcase-button {

}

#home-services {
    display: flex;

    justify-items: center;
    justify-content: center;
}

#home-services-title {
    text-align: center;
    margin: auto;
    margin-top: 30px;
    font-size: var(--heading-xl);
}


#home-steps-title {
    text-align: center;
    margin: 0em 1em 0em 1em;
    font-size: var(--heading-xl);
}


#home-photo-introduction {
    background: rgb(152, 0, 255);
    background: linear-gradient(155deg, rgba(152, 0, 255, 1) 0%, rgba(0, 255, 246, 1) 100%);

    min-height: 20em;

    margin-top: 5em;

    padding-left: 15px;
    padding-right: 15px;

    color: white;

    display: grid;


    align-items: center;
    justify-items: center;
}

#home-photo-introduction-text {
    margin: 20px;
    text-align: center;
}

#home-photo-introduction-title {
    font-size: 20px
}

#home-photo-introduction-text #home-photo-introduction-description p {
    font-size: var(--heading-s);
    padding-right: 1em;
    padding-left: 2em;
}

#home-photo-introduction-image img {
    height: 16em;
    width: 24em;

    border-radius: 10px;
}


#home-web-styles {


    padding-top: 15px;
}

#home-web-styles-title {
    text-align: center;
    padding: 0px 5px 0px 5px;
}

#home-web-styles-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: white;

    font-weight: 400;
}

#home-web-styles-container a {
    font-size: 22px;

    padding: 10px 20px 10px 20px;

    margin: 0 2em 0 2em;
}

#home-web-styles a:hover {
    cursor: pointer;
}

#home-web-styles-ex1 {
    background-color: var(--primary);
    border-radius: 5px;
}


#home-web-styles-ex2 {
    background-color: transparent;
    border-radius: 5px;

    border: 2px solid white;
}

#home-web-styles-ex3 {
    background: rgb(19, 0, 255);
    background: linear-gradient(155deg, rgba(19, 0, 255, 1) 0%, rgba(0, 170, 255, 1) 100%);

    border-radius: 5px;

    transition: background-color 0.2s linear;
}

#home-web-styles-ex3:hover {
    background: linear-gradient(155deg, rgba(190, 0, 255, 1) 0%, rgba(0, 58, 255, 1) 100%);
}

#home-web-styles-ex4 {
    background: white;
    color: black;

    transition: background-color 0.2s linear;
    transition: color 0.2s linear;
    transition: border 0.4s linear;

    border: 2px solid black;
}

#home-web-styles-ex4:hover {
    background: transparent;
    color: white;

    border: 2px solid white;

}

#home-web-styles-ex5 {
    background-color: blanchedalmond;
    color: rgba(38, 38, 38, 0.97);

    border-radius: 40px;

    transition: background-color 0.3s ease-out;
}

#home-web-styles-ex5:hover {
    background-color: #ffe5a5;
    color: rgba(38, 38, 38, 0.97);

    border-radius: 40px;
}

#home-revenue {
    height: 350px;
    position: relative;
    margin-top: 30px;
}

#home-revenue-text {
    position: absolute;
    width: 100%;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,0.8) 0%, rgba(255,255,255,0) 100%);

    color: white;
    height: 100%;
    margin-right: 50px;
    text-align: center;

}

#home-revenue-description {
    font-weight: 400;
    font-size: var(--heading-s);
    margin-bottom: 40px;

}

#home-revenue-title {
    font-weight: 600;
    font-size: var(--heading-xl);
    margin-bottom: 0px;

}



#home-revenue-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

#about-me {
    display: flex;
    align-content: center;
    align-items: center;
}

#about-me img {
    height: 200px;
    border-radius: 50%;
    margin-left: 35px;
    margin-right: 35px;
}

#about-me-texts {
    display: flex;
    flex-flow: column;
    margin: 15px;
}

#about-me-heading {
    font-size: var(--heading-m);
    font-weight: 600;
}

#about-me-heading-lastname {
    font-weight: 500;
}




/*For mobile and tablet*/
@media (max-width: 999px) {
    #home-services {
        flex-flow: column nowrap;
        align-items: center;
    }

    #home-services .info-card {
        margin-bottom: 2em;
    }

    #home-showcase-title {
        font-size: var(--heading-xl-mobile);
    }

    #home-showcase-button {
        font-size: 24px;


    }

    #about-me {
        flex-flow: column;
        text-align: center;
    }

    #home-showcase {
        height: 500px;
    }


    #home-photo-introduction {
        grid-template-rows: 50% 50%;

        height: 600px;
    }

    #home-web-styles-container {
        flex-flow: column;
        align-items: center;
    }

    #home-web-styles-container a {
        width: 100px;

        margin: 15px;

        text-align: center;
    }

}

/*For desktop*/

@media (min-width: 1000px) {
    #home-services {
        flex-flow: row nowrap;
    }

    #home-photo-introduction {
        grid-template-columns: 50% 50%;

    }
}
