Header
{
    display: grid;

    background-color: white;

    height: 100px;

    grid-template-columns: 30% 40% 30%;

    justify-items: center;
    align-items: center;

    font-size: 24px;

    z-index: 2;

    font-weight: 400;


}

#header-logo
{
    width: 50px;
    height: 50px;
}

#header-links
{
    display: flex;
    flex-flow: row;
}

#header-title a
{
    text-decoration: none;
    color: var(--text-color);
}


/*For mobile and tablet*/
@media (min-width: 320px) and (max-width: 999px)
{
    #header-links
    {
        display: none;
    }

    #header-title
    {
        display: none;
    }

    Header
    {
        display: grid;
        position: fixed;
        top: 0;
        width: 100%;
        grid-template-columns: 100% 0% 0%;
    }
}

/*For desktop*/

@media (min-width: 1000px)
{
    .bm-burger-button
    {
        display: none;
    }

    Header
    {
        position: relative;
    }
}


