.packages-container
{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  justify-items: left;
  /* Make them distributed more to the center*/

  margin: auto;
  max-width: 1200px;

}

.packages-table
{
  background-color: white;
  color: black;

  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);

  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;

  border-radius: 10px;

  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: center;

  font-size: 20px;
  padding: 5px;

  padding-bottom: 25px;

  max-width: 350px;

  text-align: center;
}

.packages-row
{
  display: flex;
  flex-flow: row;

  align-items: center;
  justify-content: center;
  justify-items: center;

  margin-bottom: 10px;
}

.packages-row-no
{
  color: #8f999a;
  font-weight: 400;
}

.packages-title
{
  font-size: 34px;
  font-weight: 400;
  color: var(--primary);

  margin: 10px;

  margin-bottom: 0px;
}

.packages-information
{
  font-weight: 400;
}

.packages-price-tag
{
  font-size: 28px;
  font-weight: 600;
  color: var(--primary);
}

.packages-price-subtitle
{
  font-size: 20px;
  font-weight: 400;
  color: var(--primary);

  margin-bottom: 20px;
}

@media(max-width: 380px)
{
  .packages-table
  {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media(max-width: 1000px) {
   .packages-container {
      flex-flow: column;
   }
}
