#page-marketing-span
{
    height: 400px;
    background: rgb(238,0,182);
    background: linear-gradient(153deg, rgba(238,0,182,1) 0%, rgba(0,105,255,1) 100%);

    color: white;

    margin: 0;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;
}

#page-marketing-span-title
{
    padding: 15px;
    font-size: var(--heading-xl);
    text-align: center;

}


